import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrRegistrationModuleEditPromoCodeView',
  computed: {
    ...mapState('promocodes', ['promocode']),
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrRegistrationModuleEditPromoCodeView extends Vue {
  data () {
    return {
      loading: false,
      submitting: false,
      promoCode: {
        code: null,
        discount_type: null,
        group_discount_type: 'ALL_GROUP_MEMBERS',
        discount_amount: null,
        discount_percent: null,
        discount_fixed_price: null,
        cap: null,
        active_start_date: null,
        active_end_date: null,
        notes: null,
        reg_types: []
      },
      regtype_specific_options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      group_style_options: [
        {
          label: 'All Group Members',
          value: 'ALL_GROUP_MEMBERS'
        },
        {
          label: 'Single Group Member',
          value: 'SINGLE_GROUP_MEMBER'
        }
      ],
      discount_type_items: [
        {
          label: 'Amount',
          value: 'AMOUNT'
        },
        {
          label: 'Percent',
          value: 'PERCENT'
        // },
        // {
        //   label: 'Fixed Price',
        //   value: 'FIXED'
        }
      ]
    }
  }

  @Watch('promocode')
  onPromoCodeChange (payload: any) {
    if (payload) {
      const promoCode = payload
      if (promoCode) {
        this.$data.promoCode = promoCode
        if (promoCode.active_start_date) {
          this.$data.promoCode.active_start_date = new Date(promoCode.active_start_date)
        }
        if (promoCode.active_end_date) {
          this.$data.promoCode.active_end_date = new Date(promoCode.active_end_date)
        }
        if ((promoCode?.reg_types || []).length > 0) {
          this.$data.promoCode.reg_types = this.registration_types.filter(type => promoCode.reg_types.includes(type.value))
        } else {
          this.$data.promoCode.reg_types = []
        }
      }
    }
  }

  async mounted () {
    await this.fetchPromoCode()
    await this.fetchOptionGroups()
  }

  async submit () {
    const form = (this.$refs.editPromoCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          promocode_uuid: this.$route.params.promo_code_uuid,
          data: {
            code: this.$data.promoCode.code,
            discount_percent: this.$data.promoCode.discount_percent,
            discount_amount: this.$data.promoCode.discount_amount,
            discount_type: this.$data.promoCode.discount_type,
            group_discount_type: this.$data.promoCode.group_discount_type,
            discount_fixed_price: this.$data.promoCode.discount_fixed_price,
            cap: this.$data.promoCode.cap,
            active_start_date: this.$data.promoCode.active_start_date,
            active_end_date: this.$data.promoCode.active_end_date,
            notes: this.$data.promoCode.notes,
            regtype_specific: this.$data.promoCode.regtype_specific,
            reg_types: this.promoCodeRegTypesAsValueString
          }
        }
        await this.$store.dispatch('promocodes/editPromoCode', payload)
        Container.get(Notification).success('Promo code successfully edited.')
        this.$router.push({
          name: 'level-two.modules.registration.promo-codes'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.reg_types = []
    }
  }

  private async fetchPromoCode () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('promocodes/getPromoCode', { event_uuid: this.$route.params.event_uuid, promo_code_uuid: this.$route.params.promo_code_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get registration_types () {
    const option_groups = this.$store.state.option.option_groups
    if (Object.keys(option_groups).length === 0) {
      return []
    }
    const registration_types_array = option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: any = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        label: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    return registration_types
  }

  /**
   * V-combobox doesn't allow populating v-model with value strings without showing the selected value in the component
   * chips instead of the label
   */
  get promoCodeRegTypesAsValueString () {
    return this.$data.promoCode.reg_types.map(object => object.value)
  }

  changeDiscountType () {
    if (this.$data.promoCode.discount_type && this.$data.promoCode.discount_type === 'AMOUNT') {
      this.$data.promoCode.discount_percent = null
    }
    if (this.$data.promoCode.discount_type && this.$data.promoCode.discount_type === 'PERCENT') {
      this.$data.promoCode.discount_amount = null
    }
  }
}
